import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import { GlobalContext } from '../../../../context/AppContext';
import AllOdds from './AllOdds';
import MainFancy from './fancy/MainFancy';
import FancyBook from './fancy/js/FancyBook';
import MasterBook from '../masterBook/MasterBook';
import { checkUser, startData, clearId } from './js/refresh';

const apiMarket = '/server2/cricket-market';

const CricMarket = () => {

const location = useLocation();
const { GetCricketMarket, isCricMarLoad, cricMarket } = GlobalContext();
const [scoreId, setScoreId] = useState(null);

const showOdds = (mid) => {
//alert(mid);
if(cricMarket.length !== 0 && cricMarket.data !== undefined){
cricMarket.data.forEach(element => {
if(mid !== 0){
if(element.mid === mid){
$('#OddsDiv_'+element.mid).show();
}
else{
$('#OddsDiv_'+element.mid).hide();
}
}
else{
$('#OddsDiv_'+element.mid).show();   
}
});
}
}

const view_score = () => {
$('#scoreFrame').toggle();
setScoreId(location.state !== null ? location.state.eventid : 0);
}

const reloadMarket = () => {
GetCricketMarket(`${apiMarket}/${location.state !== null ? location.state.eventid : 0}`);
setScoreId(null);
}

useEffect(() => {
GetCricketMarket(`${apiMarket}/${location.state !== null ? location.state.eventid : 0}`);
checkUser(location.state !== null ? location.state.eventid : 0);
return () => {
clearInterval(startData);
clearId();
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location.state]);

if(isCricMarLoad){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers_mar'>
<div className='rowWrapFull'>

<div className='game_contents'>
<div className='cols'>
<div className='multimarketdIV'>
<img src='/images/cricket.png' alt='highImg' /> 
{
location.state !== null ? 
<span id='eventname'>{location.state.eventname}</span>
:
<span id='eventname'>no event name (market not found)</span>
}
</div>

<div className='market-divWrap'>

<div className='pinDiv'>

<button onClick={() => reloadMarket()} className='pinBtn2'><i className='fa fa-refresh pinBtnIcon'></i></button>

<div className='scoreView'>
<img src='/images/speedometer.png' onClick={() => view_score()} alt='speedometer' /> 
<img src='/images/tvscore.png' alt='tvscore' /> 
</div>

</div>

{
cricMarket.length !== 0 &&
<div className='oddsAvailsBtns'>
<div className='scrollMenu'>
<button className='topOddsBtn' onClick={() => showOdds(0)}>All</button>
{
cricMarket.data !== undefined && cricMarket.data.filter((e) => e.gtype.trim() === 'match' || e.gtype.trim() === 'match1').map((data, index) => {
return (
<button className='topOddsBtn' onClick={() => showOdds(data.mid)} key={index}>{data.mname.replaceAll('_', ' ')}</button>
)
})
}
</div>
</div>
}

{
scoreId !== null &&
<iframe className='scoreFrame' id='scoreFrame' title='score' src={'https://24digital.top/liveScore_odds/'+scoreId} ></iframe>
}

<AllOdds />
<MainFancy />
<FancyBook />

</div>
</div>

<div className='cols'>
<MasterBook />
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default CricMarket;
