import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PageSideMenu from '../../extra/PageSideMenu';
import { GlobalContext } from '../../context/AppContext'

const Profit = () => {

var location = useLocation();
const Navigate = useNavigate();
const { GetProfitLoss, profitLossUser } = GlobalContext();
const [currRecords, setCurrRecords] = useState([]);
const [selectSport, setSelectSport] = useState('all');
const [totalMain, setTotalMain] = useState(0);
const [startDate, setStartDate] = useState(new Date(location.state.startDate !== undefined ? location.state.startDate : new Date()));
const [endDate, setEndDate] = useState(new Date(location.state.endDate !== undefined ? location.state.endDate : new Date().setDate(new Date().getDate() + 1)));
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currRecords.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currRecords.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const userEventPls = (sport,event) => {
var customer = location.state !== null ? location.state.name : 'none';
var role = location.state !== null ? location.state.role : 'none';
Navigate('/profit_loss/event' , {state:{sport: sport, event: event, startDate: startDate, endDate: endDate, name: customer, role: role}});
}

useEffect(() => {
var customer = location.state !== null ? location.state.name : 'none';
var select_sport = selectSport;
var start_date = new Date(startDate).toDateString();
var end_date = new Date(endDate).toDateString();
GetProfitLoss(customer,select_sport,start_date,end_date);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location.state, selectSport, startDate, endDate]);

useEffect(() => {
if(profitLossUser.length > 0){
setCurrRecords(
profitLossUser.filter((e) => e.settlement === 'settle').reduce((a, b) => {
const { sport, eventName, win_status, profit, loss} = b;
const item = a.find((y) => y.sport === sport && y.eventName === eventName);
let prf = 0;
let los = 0;
let pls = 0;

if(win_status === 'win'){
prf = prf + profit;
}else{
los = los + loss;
}

pls = parseFloat(prf) - parseFloat(los);
item ? item.pls += pls : a.push({sport,eventName,pls});
return a;
},[])
);
}else{
setCurrRecords([]);  
}
},[profitLossUser]);

useEffect(() => {
if(records.length > 0){
if(window.screen.width <= 768){
$('.usersTable').css({width: 'max-content'});
}
const totalAmt = records.reduce((a, b) => {
a = a + b.pls;
return a;
},0);

setTotalMain(totalAmt);
}
else{
setTotalMain(0);
$('.usersTable').css({width: '100%'});
}
},[records]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrap'>
<div className='col'>
<PageSideMenu />
</div>
<div className='col'>
<div className='dateDivWrap'>
<div className='dateForm2'>
<label>select sport*</label>
<select id='select_sport' onChange={(e) => setSelectSport(e.target.value)}>
<option value='all'>all</option>
<option value='null'>--select one sport--</option>
<option value='cricket'>cricket</option>
<option value='soccer'>soccer</option>
<option value='tennis'>tennis</option>
<option value='teenpatti-t20'>teenpatti t20</option>
<option value='teenpatti-oneday'>teenpatti oneday</option>
<option value='teenpatti-test'>teenpatti test</option>
<option value='dragon-tiger-t20'>dragon-tiger t20</option>
<option value='dragon-tiger-oneday'>dragon-tiger oneday</option>
<option value='lucky7-a'>lucky7 a</option>
<option value='lucky7-b'>lucky7 b</option>
<option value='andar-bahar'>andar bahar</option>
<option value='head-tail'>head tail</option>
<option value='lucky-0-to-9'>lucky 0 to 9</option>
</select>
</div>
<div className='dateForm2'>
<label>start date*</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
<div className='dateForm2'>
<label>end date*</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
</div>

<div className='profilePage'>
<div className='headerProfile'><span>profit/loss</span></div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currRecords.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable' style={{textAlign: 'center'}}>
<thead>
<tr>
<th>sport</th>
<th>event</th>
<th>profit / loss</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return (
<tr key={index}>
<td>{data.sport}</td>
<td><span className='links' onClick={() => userEventPls(data.sport,data.eventName)}>{data.eventName}</span></td>
<td>{data.pls > 0 ? <span className='successTxt'>{parseFloat(data.pls.toFixed(2)).toLocaleString()}</span> : data.pls < 0 ? <span className='dangerTxt'>{parseFloat(data.pls.toFixed(2)).toLocaleString()}</span> : 0}</td>
</tr>
)
})  
:
<tr>
<td colSpan='3'><span className='noRecordFound_td'>no record found...</span></td>
</tr>  
}
<tr>
<td><b>Total</b></td>
<td>--</td>
<td>{totalMain > 0 ? <span className='successTxt'>{parseFloat(totalMain.toFixed(2)).toLocaleString()}</span> : totalMain < 0 ? <span className='dangerTxt'>{parseFloat(totalMain.toFixed(2)).toLocaleString()}</span> : 0}</td>
</tr>
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default Profit;
