import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ParentList from '../../modals/ParentList';

const betListApi = '/server2/bet_listAll';

const BetList = () => {

const [betStatus, setBetStatus] = useState('unsettle');
const [selectSport, setSelectSport] = useState('cricket');
const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
const [historyData, setHistoryData] = useState([]);
const [searchData, setSearchData] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = historyData.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(historyData.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const searchUsers = (name) => {
setHistoryData(searchData.filter((e) => e.customer.includes(name)));
}

const parentList = (customer,admin,sub_admin,super_master,master,agent) => {
$('#parentListModal').show();
if(customer !== 'null'){
$('#customerParent').text(customer);
$('#customerParentRole').show();
}
if(admin !== 'null'){
$('#adminParent').text(admin);
$('#adminParentRole').show();
}
if(sub_admin !== 'null'){
$('#sub_adminParent').text(sub_admin);
$('#sub_adminParentRole').show();
}
if(super_master !== 'null'){
$('#super_masterParent').text(super_master);
$('#super_masterParentRole').show();
}
if(master !== 'null'){
$('#masterParent').text(master);
$('#masterParentRole').show();
}
if(agent !== 'null'){
$('#agentParent').text(agent);
$('#agentParentRole').show();
}
}

useEffect(() => {

const fetchData = async() => {
var bet_status = betStatus;
var select_sport = selectSport;
var start_date = new Date(startDate).toDateString();
var end_date = new Date(endDate).toDateString();
try {
const getData = await axios.post(`${betListApi}`, {
bet_status: bet_status,
select_sport: select_sport,
start_date: start_date,
end_date: end_date
});
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
setHistoryData(resData);
setSearchData(resData);
}
else{
setHistoryData([]);  
setSearchData([]);  
}
} catch (error) {
console.log('backend bet-history-api error: ' + error);
}
}

fetchData();
}, [betStatus, selectSport, startDate, endDate]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>
<div className='col'>
<div className='dateDivWrap'>
<div className='dateForm2'>
<label>status type*</label>
<select id='betStatus' onChange={(e) => setBetStatus(e.target.value)}>
<option>unsettle</option>
<option>settle</option>
<option>void</option>
</select>
</div>
<div className='dateForm2'>
<label>select sport*</label>
<select id='select_sport' onChange={(e) => setSelectSport(e.target.value)}>
<option value='cricket'>cricket</option>
<option value='null'>--select one sport--</option>
<option value='cricket'>cricket</option>
<option value='soccer'>soccer</option>
<option value='tennis'>tennis</option>
<option value='teenpatti-t20'>teenpatti t20</option>
<option value='teenpatti-oneday'>teenpatti oneday</option>
<option value='teenpatti-test'>teenpatti test</option>
<option value='dragon-tiger-t20'>dragon-tiger t20</option>
<option value='dragon-tiger-oneday'>dragon-tiger oneday</option>
<option value='lucky7-a'>lucky7 a</option>
<option value='lucky7-b'>lucky7 b</option>
<option value='andar-bahar'>andar bahar</option>
<option value='head-tail'>head tail</option>
<option value='lucky-0-to-9'>lucky 0 to 9</option>
</select>
</div>
<div className='dateForm2'>
<label>start date*</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
<div className='dateForm2'>
<label>end date*</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
</div>

<div className='profilePage'>
<div className='headerProfile'><span>bet list</span></div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search users...' onChange={(e) => searchUsers(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {historyData.length} records...</span>
</div>

<div className='table_responsiveMx'>
<table className='usersTableLg'>
<thead>
<tr>
<th>customer</th>
<th>sport</th>
<th>event</th>
<th>runner</th>
<th>sec</th>
<th>bet type</th>
<th>bet rate</th>
<th>bet size</th>
<th>bet amount</th>
<th>profit</th>
<th>loss</th>
<th>settlement</th>
<th>winner</th>
<th>match date</th>
<th>place date</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
var bet_type;
if(data.bettype === 'back'){
if(data.sec === 'fancy' || data.sec === 'ball' || data.sec === 'over' || data.sec === 'meter' || data.sec === 'khado' || data.sec === 'oddeven' || data.sec === 'fancy1' || data.sec === 'line'){
bet_type = <span className='backHis'>yes</span>;  
}
else{
bet_type = <span className='backHis'>{data.bettype}</span>;  
}
}
else{
if(data.sec === 'fancy' || data.sec === 'ball' || data.sec === 'over' || data.sec === 'meter' || data.sec === 'khado' || data.sec === 'oddeven' || data.sec === 'fancy1' || data.sec === 'line'){
bet_type = <span className='layHis'>no</span>;  
}
else{
bet_type = <span className='layHis'>{data.bettype}</span>;  
}
}

const selfDate = data.bet_dateTime.split(',');
//const selfYear = selfDate[0].split('/');
//const setYear = selfYear[2] +'/'+ selfYear[1] +'/'+ selfYear[0] +','+ selfDate[1];
var parseDat = Date.parse(selfDate) - (data.betDelay * 1000);
const setNewDate = new Date(parseDat).toLocaleString().split(',');
const dateBreak = setNewDate[0].split('/');
//console.log(selfDate +' -- '+ selfYear +' -- '+ setYear +' // '+ parseDat +' -- '+ setNewDate +' -- '+ dateBreak +' -- '+ setNewDate[1]);
var placeDate = dateBreak[0] +'/'+ dateBreak[1] +'/'+ dateBreak[2] +','+ setNewDate[1];

return (
<tr key={index}>
<td><span className='links' onClick={() => parentList(data.customer,data.admin,data.sub_admin,data.super_master,data.master,data.agent)}>{data.customer}</span></td>
<td>{data.sport}</td>
<td>{data.eventName}</td>
<td>{data.team}</td>
<td>{data.sec}</td>
<td>{bet_type}</td>
<td>{data.betRate}</td>
<td>{data.betSize !== null ? data.betSize : 0}</td>
<td>{parseFloat(data.betamount.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.profit.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.loss.toFixed(2)).toLocaleString()}</td>
<td>{data.settlement}</td>
<td>{data.settlement === 'settle' ? data.winner : data.settlement === 'unsettle' ? 'none' : 'void'}</td>
<td>{placeDate}</td>
<td>{data.bet_dateTime}</td>
</tr>
)
})  
:
<tr>
<td colSpan='15'><span className='noRecordFound_td'>no record found...</span></td>
</tr>  
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

<ParentList />
</>
)
}

export default BetList;
