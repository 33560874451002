import React, { useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import axios from 'axios';
import StatusAlert from './StatusAlert';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Home = () => {

const [currRepo, setCurrRepo] = useState([]);
const [liveRepo, setLiveRepo] = useState([]);
const [liveOpt, setLiveOpt] = useState([]);
const [backRepo, setBackRepo] = useState([]);
const [backOpt, setBackOpt] = useState([]);

const options = {
animationEnabled: true,
exportEnabled: false,
theme: "light1", // "light1", "dark1", "dark2"
/*
title:{
text: "Trip Expenses"
},
*/
data: [{
type: "pie",
indexLabel: "{label}: {y} ₹",		
startAngle: -90,
dataPoints: liveOpt
}]
}

const options2 = {
animationEnabled: true,
exportEnabled: false,
theme: "light1", // "light1", "dark1", "dark2"
/*
title:{
text: "Trip Expenses"
},
*/
data: [{
type: "pie",
indexLabel: "{label}: {y} ₹",		
startAngle: -90,
dataPoints: backOpt
}]
}

for(let i=0; i < options.data[0].dataPoints.length; i++){
if(options.data[0].dataPoints[i].y >= 0){
options.data[0].dataPoints[i].indexLabelFontColor = '#009933';
options.data[0].dataPoints[i].labelFontWeight = 'bold';
}
else{
options.data[0].dataPoints[i].indexLabelFontColor = '#e60000';  
options.data[0].dataPoints[i].labelFontWeight = 'bold'; 
}
}

for(let i=0; i < options2.data[0].dataPoints.length; i++){
if(options2.data[0].dataPoints[i].y >= 0){
options2.data[0].dataPoints[i].indexLabelFontColor = '#009933';
options2.data[0].dataPoints[i].labelFontWeight = 'bold';
}
else{
options2.data[0].dataPoints[i].indexLabelFontColor = '#e60000';  
options2.data[0].dataPoints[i].labelFontWeight = 'bold'; 
}
}

useEffect(() => {
async function fetchData(){
try {
const sendData = await axios.get('/server2/settle_betsAll');
const resData = await sendData.data;
if(resData.length > 0){
setCurrRepo(resData);
}
else{
setCurrRepo([]);
}
} catch (error) {
console.log('backend home-settle-bet-api error : ' + error);
}
}

fetchData();
},[]);

useEffect(() => {
if(currRepo.length > 0){
var findDate = new Date().toDateString();
setLiveRepo(currRepo.filter((e) => e.bet_date >= Date.parse(findDate)).reduce((a, b) => {
const { sport, win_status, profit, loss } = b;
const item = a.find((f) => f.sport === sport);
let prf = 0;
let los = 0;
let pls = 0;

if(win_status === 'win'){
prf = prf + profit;
}
else{
los = los + loss;
}

pls = parseFloat(prf) - parseFloat(los);
item ? item.pls += pls : a.push({sport,pls});
return a;
},[]));

setBackRepo(currRepo.filter((e) => e.bet_date < Date.parse(findDate)).reduce((a, b) => {
const { sport, win_status, profit, loss } = b;
const item = a.find((f) => f.sport === sport);
let prf = 0;
let los = 0;
let pls = 0;

if(win_status === 'win'){
prf = prf + profit;
}
else{
los = los + loss;
}

pls = parseFloat(prf) - parseFloat(los);
item ? item.pls += pls : a.push({sport,pls});
return a;
},[]));
}
},[currRepo]);

useEffect(() => {
var allLive = [];
if(liveRepo.length > 0){
liveRepo.forEach((e) => {
var data = {
y: e.pls,
label: e.sport
}

const findKey = allLive.findIndex((O) => O.label === e.sport);
if(findKey === -1){
allLive.push(data);
}
});
}
else{
allLive.push({y: 1, label: 'cricket'});
}
setLiveOpt(allLive);
},[liveRepo]);

useEffect(() => {
var allLive = [];
if(backRepo.length > 0){
backRepo.forEach((e) => {
var data = {
y: e.pls,
label: e.sport
}

const findKey = allLive.findIndex((O) => O.label === e.sport);
if(findKey === -1){
allLive.push(data);
}
});
}
else{
allLive.push({y: 1, label: 'cricket'});
}
setBackOpt(allLive);
},[backRepo]);

return (
<>
<div className='content-body'>
<div className='containers'>
<StatusAlert />
<div className='rowWrap2'>
<div className='col'>
<div className='headTitle'><span>live sport profit/loss</span></div>
<CanvasJSChart options={options} />
</div>
<div className='col'>
<div className='headTitle'><span>backup sport profit/loss</span></div>
<CanvasJSChart options={options2} />
</div>
</div>

</div>
</div>
</>
)
}

export default Home;
