import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/AppContext';

const Settlement = () => {

const navigate = useNavigate();
const { unsetBets } = GlobalContext();
const [sports, setSports] = useState([]);
const [events, setEvents] = useState([]);

const openSettle = (sport,event,eventid) => {
navigate('/view_settle', {state:{sport: sport, event: event, eventid: eventid}});
}

useEffect(() => {
if(unsetBets.length > 0){
var allSports = [];
var allEvents = [];
unsetBets.forEach((e) => {
var data = {
sport: e.sport
}

var data2 = {
sport: e.sport,
eventname: e.eventName,
eventid: e.eventid    
}

const findInd = allSports.findIndex((o) => o.sport === e.sport);
const findInd2 = allEvents.findIndex((o) => o.eventname === e.eventName && parseInt(o.eventid) === parseInt(e.eventid));

if(findInd === -1){
allSports.push(data); 
}

if(findInd2 === -1){
allEvents.push(data2); 
}
});
setSports(allSports);
setEvents(allEvents);
}
},[unsetBets]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='profilePage'>
<div className='headerProfile'><span>settlement</span></div>

<div className='stateBody'>
{
sports.length > 0 ? sports.map((data, index) => {
return (
<div className='sportsWrap_settle' key={index}>
<div className='sportsTitle_settle'><span>{data.sport}</span></div>
<div className='sportsBody_settle'>
{
events.length > 0 && events.filter((e) => e.sport === data.sport).map((data2, index2) => {
return (
<span onClick={() => openSettle(data2.sport,data2.eventname,data2.eventid)} key={index2}>{data2.eventname}</span>
)
})
}
</div> 
</div>
)
})
:
<div className='norecordSettle'>
<img src='/images/cloud.png' alt='cloud' />
<span>no record found...</span>
</div>
}
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default Settlement;
