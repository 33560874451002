import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import PageSideMenu from '../../extra/PageSideMenu';
import { GlobalContext } from '../../context/AppContext';

const History = () => {

const location = useLocation();
const  { GetProfitLoss, profitLossUser } = GlobalContext();
const [currEvent, setCurrEvent] = useState([]);

useEffect(() => {
var customer = location.state !== null ? location.state.name : 'none';
var select_sport = location.state !== null ? location.state.sport : 'none';
var start_date = new Date(location.state !== null && location.state.startDate).toDateString();
var end_date = new Date(location.state !== null && location.state.endDate).toDateString();
GetProfitLoss(customer,select_sport,start_date,end_date);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location.state]);

useEffect(() => {
if(profitLossUser.length > 0){
if(location.state !== null && (location.state.sec === 'fancy' || location.state.sec === 'ball' || location.state.sec === 'over' || location.state.sec === 'meter' || location.state.sec === 'khado' || location.state.sec === 'oddeven' || location.state.sec === 'fancy1' || location.state.sec === 'line')){
setCurrEvent(profitLossUser.filter((e) => e.settlement === 'settle' && e.sport === location.state.sport && e.eventName === location.state.event && e.team === location.state.team && e.customer === location.state.name && e.eventid === location.state.eventid));
}
else{
setCurrEvent(profitLossUser.filter((e) => e.settlement === 'settle' && e.sport === location.state.sport && e.eventName === location.state.event && e.sec === location.state.sec && e.customer === location.state.name && e.eventid === location.state.eventid));
}
}
},[profitLossUser, location.state]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrap'>
<div className='col'>
<PageSideMenu />
</div>

<div className='col'>
<div className='profilePage'>
<div className='headerProfile'><span>{location.state.user} bet history</span></div>

<div className='stateBody'>
<div className='table_responsiveMx'>
<table className='usersTable3'>
<thead>
<tr>
<th>customer</th>
<th>sport name</th>
<th>event name</th>
<th>market name</th>
<th>runner name</th>
<th>bet type</th>
<th>odds</th>
<th>size</th>
<th>bet amount</th>
<th>profit</th>
<th>loss</th>
<th>result</th>
<th>match date</th>
<th>place date</th>
</tr>
</thead>
<tbody>
{
currEvent.length > 0 ? currEvent.map((data, index) => {
const selfDate = data.bet_dateTime.split(',');
//const selfYear = selfDate[0].split('/');
//const setYear = selfYear[2] +'/'+ selfYear[1] +'/'+ selfYear[0] +','+ selfDate[1];
var parseDat = Date.parse(selfDate) - (data.betDelay * 1000);
const setNewDate = new Date(parseDat).toLocaleString().split(',');
const dateBreak = setNewDate[0].split('/');
//console.log(selfDate +' -- '+ selfYear +' -- '+ setYear +' // '+ parseDat +' -- '+ setNewDate +' -- '+ dateBreak +' -- '+ setNewDate[1]);
var placeDate = dateBreak[0] +'/'+ dateBreak[1] +'/'+ dateBreak[2] +','+ setNewDate[1];
if(data.bettype === 'back'){
return(
<tr key={index} className='backTr'>
<td>{data.customer}</td>
<td>{data.sport}</td>
<td>{data.eventName}</td>
<td>{data.sec}</td>
<td>{data.team}</td>
<td>
{
data.sec === 'fancy' || data.sec === 'ball' || data.sec === 'over'
|| data.sec === 'meter' || data.sec === 'khado' || data.sec === 'oddeven'
|| data.sec === 'fancy1' || data.sec === 'line' ? 'yes' : data.bettype
}
</td>
<td>{data.betRate}</td>
<td>{data.betSize !== null ? data.betSize : 0}</td>
<td>{parseFloat(data.betamount.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.profit.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.loss.toFixed(2)).toLocaleString()}</td>
<td>{data.settlement === 'settle' ? data.winner : 'void'}</td>
<td>{placeDate}</td>
<td>{data.bet_dateTime}</td>
</tr>
)
}
else{
return(
<tr key={index} className='layTr'>
<td>{data.customer}</td>
<td>{data.sport}</td>
<td>{data.eventName}</td>
<td>{data.sec}</td>
<td>{data.team}</td>
<td>
{
data.sec === 'fancy' || data.sec === 'ball' || data.sec === 'over'
|| data.sec === 'meter' || data.sec === 'khado' || data.sec === 'oddeven'
|| data.sec === 'fancy1' || data.sec === 'line' ? 'no' : data.bettype
}
</td>
<td>{data.betRate}</td>
<td>{data.betSize !== null ? data.betSize : 0}</td>
<td>{parseFloat(data.betamount.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.profit.toFixed(2)).toLocaleString()}</td>
<td>{parseFloat(data.loss.toFixed(2)).toLocaleString()}</td>
<td>{data.settlement === 'settle' ? data.winner : 'void'}</td>
<td>{placeDate}</td>
<td>{data.bet_dateTime}</td>
</tr>
)
}
})
:
<tr>
<td colSpan='14' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>
</div>

</div>
</div>

</div>
</div>
</div>
</>
)
}

export default History;
